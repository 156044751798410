/** @jsxImportSource @emotion/react */
import React from "react";
import { HeaderAvatar } from "./HeaderAvatar";
import { css } from "@emotion/react";

export function Header() {
  return (
    <header
      css={css`
        background-image: url("static/images/greenery.png"),
          url("static/images/greenery-2.png");
        background-size: 40%, 40%;
        background-repeat: no-repeat;
        background-position: top left, top right;
        min-height: 18vh;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;

        @media (min-width: 600px) {
          background-size: 35%, 35%;
        }
      `}
    >
      <div
        css={css`
          height: 10rem;
        `}
      ></div>
      <HeaderAvatar
        src={"static/images/avatar.jpg"}
        width={256}
        height={256}
        alt="Jonas & Olivia"
      />
    </header>
  );
}
