/** @jsxImportSource @emotion/react */
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Header } from "./components/Header";
import { WelcomeText } from "./components/WelcomeText";

export default function App() {
  //const { user } = useAuth();

  return (
    <Grid container>
      <Grid xs={12}>
        <Header />
      </Grid>
      <Grid xs={12}>
        <WelcomeText
          salutation="Nu är det dags för bröllop"
          names="Jonas & Olivia"
          punchline="Häng med, det blir kul!"
          date="9 augusti 2025"
          color="#6d9a5d"
        />
      </Grid>
      {/* <Grid xs={12}>
        <WeddingDate date="9 augusti 2025" />
      </Grid> */}
      {/* {user ? (
        <Grid xs={12}>
          <QuickRsvp text="9 augusti 2025" buttonText="OSA" />
        </Grid>
      ) : (
        <Login />
      )} */}
    </Grid>
  );
}
