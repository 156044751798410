/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Typography } from "@mui/material";

export interface WelcomeTextProps {
  salutation: string;
  names: string;
  punchline: string;
  date: string;
  color?: string;
}

const defaultColor = "rgb(48, 70, 101)";

export function WelcomeText(props: WelcomeTextProps) {
  const color = props.color ? props.color : defaultColor;

  return (
    <>
      <h5
        css={css`
          color: ${color};
          text-transform: uppercase;
          text-align: center;
          font-family: inherit;
          font-weight: 400;
          font-size: 2rem;
          margin-block-start: 1rem;
          margin-block-end: 1rem;
        `}
      >
        {props.salutation}
      </h5>
      <h1
        style={{ margin: "0" }}
        css={css`
          color: ${color};
          text-transform: uppercase;
          text-align: center;
          font-family: inherit;
          font-weight: 700;
          font-size: 4rem;
          margin-block-start: 1rem;
          margin-block-end: 1rem;
        `}
      >
        {props.names}
      </h1>
      <h5
        style={{ marginTop: "0" }}
        css={css`
          color: ${color};
          text-transform: uppercase;
          text-align: center;
          font-family: inherit;
          font-weight: 400;
          font-size: 3rem;
          margin-top: 0;
          margin-block-start: 1rem;
          margin-block-end: 1rem;
        `}
      >
        {props.date}
      </h5>
      <h5
        css={css`
          color: ${color};
          text-transform: uppercase;
          text-align: center;
          font-family: inherit;
          font-weight: 400;
          font-size: 2rem;
          margin-block-start: 1rem;
          margin-block-end: 1rem;
        `}
      >
        {props.punchline}
      </h5>
      <Typography
        variant="body1"
        align="center"
        color={{ color }}
        marginTop={"3rem"}
        marginBottom={"3rem"}
        fontFamily={"inherit"}
        fontSize={"2rem"}
      >
        PS: Formell inbjudan kommer senare
      </Typography>
    </>
  );
}
