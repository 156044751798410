/** @jsxImportSource @emotion/react */
import React from "react";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Unstable_Grid2";

export interface HeaderAvatarProps {
  src: string;
  alt?: string;
  width?: number;
  height?: number;
}

export function HeaderAvatar(props: HeaderAvatarProps) {
  return (
    <Grid container direction="column" alignItems={"center"}>
      <Grid>
        <Avatar
          sx={{ width: props.width ?? 64, height: props.height ?? 64 }}
          alt={props.alt ? props.alt : "Avatar"}
          src={props.src}
        />
      </Grid>
    </Grid>
  );
}
